<template>
  <base-layout page-defaul-back-link="/home/Inicio">
  <div class="container">
  <register-enter-form></register-enter-form>
  </div>
  </base-layout>
</template>

<script>
import RegisterEnterForm from '../components/employe/RegisterEnterForm.vue'
export default {
  components: { RegisterEnterForm },
    name: 'entrada-page'

}
</script>

<style>

</style>